import React from "react";

import ButtonGroup from "./ButtonGroup/ButtonGroup";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import "./Modal.scss";

const Modal = (props) => {
  const {
    modalTitle,
    modalDisplay,
    cancelText,
    provisionType,
    close,
    isDisabled,
    handleProvisioning,
    children,
    isLoading,
    isDraftBtn,
    isDraftBtnDisabled,
    handleSaveAsDraft,
    moveToTrash,
    feedbackMsgEnabled,
    feedbackMsg,
    isBtnDisabled = false,
    formError = false,
    formErrMsg = "",
    queryLengthError = false,
  } = props;

  return (
    <div className="answer-modal modal-comp" style={modalDisplay}>
      <div
        className={`modal-content ${
          feedbackMsgEnabled ? "feedback-modal" : null
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-inner-wrapper">
          {!isLoading &&
          modalTitle &&
          modalTitle.length &&
          !feedbackMsgEnabled ? (
            <div className="modal-title-container">
              <p className="modal-title">{modalTitle}</p>
            </div>
          ) : null}
          {!feedbackMsgEnabled ? (
            children
          ) : (
            <div className="feedback-msg-content">
              <p>{feedbackMsg}</p>
            </div>
          )}
          <CloseOutlinedIcon
            onClick={close}
            className="close-modal-icon"
            fontSize="small"
          />
        </div>
        {formError && <p className="form-err-msg">{formErrMsg}</p>}
        {queryLengthError && (
          <p className="form-err-msg">
            {"Query length more than 150 characters"}
          </p>
        )}
        {!isLoading && !feedbackMsgEnabled ? (
          <ButtonGroup
            close={close}
            isDisabled={isDisabled}
            handleProvisioning={handleProvisioning}
            cancelText={cancelText}
            provisionType={provisionType}
            isDraftBtn={isDraftBtn}
            moveToTrash={moveToTrash !== "" ? moveToTrash : null}
            isDraftBtnDisabled={isDraftBtnDisabled}
            handleSaveAsDraft={handleSaveAsDraft}
            isBtnDisabled={isBtnDisabled}
          />
        ) : null}
        {feedbackMsgEnabled ? (
          <ButtonGroup close={close} cancelText={"OK"} />
        ) : null}
      </div>
    </div>
  );
};

export default Modal;
