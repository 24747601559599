/* Website pages */
export const HOME_PAGE = '/';
export const LOGOUT_PAGE = '/logout';
export const PRICING_PAGE = '/pricing';
export const CONTACT_US_PAGE = '/support';
export const PRIVACY_POLICY_PAGE = '/privacy-policy';
export const TERMS_AND_CONDITIONS_PAGE = '/terms-and-conditions';
export const INTEGRATIONS_PAGE = '/integrations';
export const FORGOT_PASSWORD_PAGE = '/forgot-password';
export const PASSWORD_RESET_PAGE = '/password-reset';

/* Dashboard pages */
export const DASHBOARD_PAGE = '/dashboard';
export const COLLECTION_QUESTIONS_PAGE = `${DASHBOARD_PAGE}/collections/collection-ques`;
export const QUESTION_DETAIL_PAGE = `${DASHBOARD_PAGE}/question-details`;
export const GET_STARTED_PAGE = `${DASHBOARD_PAGE}/get-started`;
export const MY_INTERESTS_PAGE = `${DASHBOARD_PAGE}/my-interests`;
export const SETTINGS_PAGE = `${DASHBOARD_PAGE}/settings`;
export const MY_DRAFTS_PAGE = `${DASHBOARD_PAGE}/my-drafts`;
export const MY_POSTS_PAGE = `${DASHBOARD_PAGE}/my-posts`;
export const ANALYTICS_PAGE = `${DASHBOARD_PAGE}/analytics`;
export const COLLECTIONS_PAGE = `${DASHBOARD_PAGE}/collections`;

/* Settings pages */
export const TEAM_SETTINGS_PAGE = `${SETTINGS_PAGE}/team`;
export const BILLING_SETTINGS_PAGE = `${SETTINGS_PAGE}/billing`;
export const BILLING_SUCCESS_PAGE = `${BILLING_SETTINGS_PAGE}/success`;
export const BILLING_FAILURE_PAGE = `${BILLING_SETTINGS_PAGE}/failure`;
export const SETTINGS_PAGE_ADD_INTEGRATION = `${SETTINGS_PAGE}/add-integration`;
export const SETTINGS_PRICING_PAGE = `${SETTINGS_PAGE}/pricing`;

/* User login/signup journey pages */
export const SIGNUP_PAGE = '/signup/new-user';
export const SIGN_IN_PAGE = '/signin/existing-user-check';
export const USER_INVITE_PAGE = '/user-invite';
export const SETTING_UP_PAGE = '/setting-up';
export const ORGANIZATION_PAGE = '/organization'; /* Navigates to the page which shows either the team name drodpown or text field to enter team name */
export const ORGANIZATION_CHECK_PAGE = '/organization-check';

/* OAuth page */
export const GOOGLE_AUTH_PAGE = '/auth/google';
