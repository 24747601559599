import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";

import {
  USER_SIGN_UP,
  RESET_APP,
  ACTION_ID,
  TEAM_INVITE,
} from "../../actions/types";

import { isEmailValid, isEmpty } from "../../utils";

import { TANK_LOGO_BLUE } from "../../constants";
import {
  SETTING_UP_PAGE,
  ORGANIZATION_PAGE,
  HOME_PAGE,
  TERMS_AND_CONDITIONS_PAGE,
  GOOGLE_AUTH_PAGE,
  SIGN_IN_PAGE,
  PRIVACY_POLICY_PAGE,
} from "../../constants/PageRoutes";

import Logo from "../../components/Logo/Logo";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import "./SignUpUserPage.scss";

class SignUpUserPage extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    firstNameErr: false,
    firstNameErrMsg: "",
    lastNameErr: false,
    lastNameErrMsg: "",
    emailErr: false,
    emailErrMsg: "",
    passwordErr: false,
    passwordErrMsg: "",
    emailInvite: "",
    teamNameInvite: "",
    showPassword: false,
  };

  componentDidMount() {
    if (this.props.location && this.props.location.search) {
      const {
        location: { search },
        setOnboardingActionId,
        setInvitedTeamDetails,
      } = this.props;
      const invitedEmail = new URLSearchParams(search).get("email");
      const invitedTeamName = new URLSearchParams(search).get("teamname");

      this.setState(
        { email: invitedEmail, teamNameInvite: invitedTeamName },
        () => {
          const body = {
            emailInvited: this.state.email,
            teamNameInvited: this.state.teamNameInvite,
          };

          setOnboardingActionId("invite");
          setInvitedTeamDetails(body);
        }
      );
    }
  }

  firstNameChange = (firstName) => {
    this.setState({ firstName });
  };

  lastNameChange = (lastName) => {
    this.setState({ lastName });
  };

  emailChange = (email) => {
    this.setState({ email });
  };

  passwordChange = (password) => {
    this.setState({ password, passwordErr: false, passwordErrMsg: "" });
  };

  emailValidityCheck = () => {
    const { email } = this.state;

    if (isEmailValid(email)) {
      this.setState({ emailErr: false, emailErrMsg: "" });
    } else {
      this.setState({ emailErr: true, emailErrMsg: "Invalid email address" });
    }
  };

  submitForm = async () => {
    const { firstName, lastName, email, password, teamNameInvite } = this.state;
    const { userSignUpData } = this.props;

    const body = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
    };

    try {
      const signUpRes = await axios.post("/auth/local/signup", body);
      if (signUpRes.data && signUpRes.data.success) {
        userSignUpData(signUpRes.data.userInfo);
        if (teamNameInvite) {
          window.location.href = SETTING_UP_PAGE;
        } else {
          window.location.href = ORGANIZATION_PAGE;
        }
      }
    } catch (err) {
      if (err.response.status === 422) {
        this.setState({ emailErr: true, emailErrMsg: "Email already exists" });
      }
    }
  };

  isValidString = (val) => {
    const regex = /^[a-zA-Z]+$/;

    return !isEmpty(val) && regex.test(val);
  };

  isFormValid = (e) => {
    e.preventDefault();

    const { firstName, lastName, email, password } = this.state;

    if (
      this.isValidString(firstName) &&
      this.isValidString(lastName) &&
      isEmpty(email) &&
      isEmpty(password)
    ) {
      this.setState({
        firstNameErr: true,
        firstNameErrMsg: "First name is required",
        lastNameErr: true,
        lastNameErrMsg: "Last name is required",
        emailErr: true,
        emailErrMsg: "Email address is required",
        passwordErr: true,
        passwordErrMsg: "Password is required",
      });
    } else if (isEmpty(email)) {
      this.setState({
        emailErr: true,
        emailErrMsg: "Email address is required",
      });
    } else if (!isEmailValid(email)) {
      this.setState({
        emailErr: true,
        emailErrMsg: "Invalid email address",
      });
    } else if (isEmpty(password)) {
      this.setState({
        passwordErr: true,
        passwordErrMsg: "Password is required",
      });
    } else if (!this.isValidString(firstName)) {
      this.setState({
        firstNameErr: true,
        firstNameErrMsg: "Invalid first name",
      });
    } else if (!this.isValidString(lastName)) {
      this.setState({
        lastNameErr: true,
        lastNameErrMsg: "Invalid last name",
      });
    } else {
      this.submitForm();
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  checkIsInvitedUser = () => {
    const {
      location: { search },
    } = this.props;
    const invitedTeamName = new URLSearchParams(search).get("teamname");
    const inviteId = new URLSearchParams(search).get("actionid") || "";
    const isInviteActionId = inviteId === "invite";
    const inviteDetails = {
      inviteId,
      invitedTeamName,
    };

    if (isInviteActionId) {
      window.localStorage.setItem(
        "inviteDetails",
        JSON.stringify(inviteDetails)
      );
    }

    window.location.href = GOOGLE_AUTH_PAGE;
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      firstNameErr,
      firstNameErrMsg,
      lastNameErr,
      lastNameErrMsg,
      emailErr,
      emailErrMsg,
      passwordErr,
      passwordErrMsg,
      teamNameInvite,
      showPassword,
    } = this.state;

    return (
      <div className="user-sign-up full-page-height page-top">
        <div className="screen-max-width">
          <div className="goto-tank">
            <span>GO BACK TO</span>
            <a className="logo-container" href={HOME_PAGE}>
              <Logo imageUrl={TANK_LOGO_BLUE} />
            </a>
          </div>
          <Grid container spacing={3} className="sign-up-wrapper">
            <Grid item xs={12} md={6} lg={6} className="sign-up-item-form">
              <h2>Sign Up</h2>
              <form onSubmit={this.isFormValid}>
                <TextField
                  id="first-name"
                  label="First Name*"
                  type="text"
                  autoComplete="current-firstname"
                  className="form-field firstname"
                  onChange={(e) => this.firstNameChange(e.target.value)}
                  // onBlur={this.firstNameValidityCheck}
                  value={firstName}
                  error={firstNameErr}
                  helperText={firstNameErrMsg}
                />
                <TextField
                  id="last-name"
                  label="Last Name*"
                  type="text"
                  autoComplete="current-lastname"
                  className="form-field lastname"
                  onChange={(e) => this.lastNameChange(e.target.value)}
                  // onBlur={this.lastNameValidityCheck}
                  value={lastName}
                  error={lastNameErr}
                  helperText={lastNameErrMsg}
                />
                <TextField
                  id="sign-up-email"
                  label="Email Address*"
                  type="email"
                  autoComplete="current-email"
                  className="form-field email"
                  onChange={(e) => this.emailChange(e.target.value)}
                  onBlur={this.emailValidityCheck}
                  value={email}
                  disabled={teamNameInvite ? true : false}
                  error={emailErr}
                  helperText={emailErrMsg}
                />
                <TextField
                  id="sign-up-password"
                  label="Password*"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  className="form-field password"
                  onChange={(e) => this.passwordChange(e.target.value)}
                  error={passwordErr}
                  helperText={passwordErrMsg}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon fontSize="small" />
                          ) : (
                            <VisibilityIcon fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <p className="password-helper">Must be atleast 8 characters</p>
                <div className="form-action-btns">
                  <Button className="primary-btn thin" type="submit">
                    Create account
                  </Button>
                  <p className="or">
                    <span>OR</span>
                  </p>
                  <Button
                    onClick={this.checkIsInvitedUser}
                    // href={GOOGLE_AUTH_PAGE}
                    className="primary-btn thin google"
                  >
                    Sign Up with Google
                  </Button>
                </div>
                <p className="entry-navigate">
                  Already have an account?{" "}
                  <NavLink to={SIGN_IN_PAGE}>Sign In</NavLink>
                </p>
              </form>
            </Grid>
            <Grid item xs={12} md={6} lg={6} className="sign-up-item-desc">
              <div className="desc-text">
                <p>
                  Leverage the power of centralised information and stronger
                  team collaboration.
                </p>
                <p className="try-tank-text">
                  <span>Try</span> <Logo imageUrl={TANK_LOGO_BLUE} />{" "}
                  <span>today.</span>
                </p>
              </div>
              <div className="sign-up-asset">
                <img
                  src={require("../../assets/images/sign-up-asset.png")}
                  alt="sign up"
                />
              </div>
              <div className="bubble-asset">
                <img
                  src={require("../../assets/images/bubble-creative.png")}
                  alt="bubble"
                />
              </div>
            </Grid>
          </Grid>
          <div className="create-tank-acnt-terms">
            <p className="entry-navigate">
              By creating an account on Tank you accept our{" "}
              <NavLink to={TERMS_AND_CONDITIONS_PAGE}>Terms of Service</NavLink>{" "}
              and <NavLink to={PRIVACY_POLICY_PAGE}>Privacy Policy</NavLink>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signedUpUser: state.signedUpUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  userSignUpData: (data) => dispatch({ type: USER_SIGN_UP, payload: data }),
  setOnboardingActionId: (data) => dispatch({ type: ACTION_ID, payload: data }),
  setInvitedTeamDetails: (data) =>
    dispatch({ type: TEAM_INVITE, payload: data }),
  resetAppData: () => dispatch({ type: RESET_APP }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpUserPage);
