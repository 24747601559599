import React from 'react';

import Button from '@material-ui/core/Button';

import './TankButton.scss';

const TankButton = props => {
  const { btnText, btnOnClick } = props;
  return (
    <div className='tank-btn'>
      <Button onClick={btnOnClick}>
        {btnText}
      </Button>
    </div>
  )
}

export default TankButton;
