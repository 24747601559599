export const TANK_EDITOR_API_KEY =
  "cntsjxb4rzel5sryt0rp4yeiucxil53pxs9j2upjn0b1i9az";

const api = "/api";
const apiV1 = `${api}/v1`;

const utilsBaseURL = `${apiV1}/utils`;
export const userBaseURL = `${apiV1}/user`;
const queryBaseURL = `${apiV1}/query`;
const draftBaseURL = `${apiV1}/draft`;
const notificationBaseURL = `${apiV1}/notification`;
const stripeBaseURL = "/stripe";
const slackBaseURL = `${apiV1}/slackapi`;

/*Utils APIs*/

export const contactUs = `${utilsBaseURL}/support/customerQuerySupport`;

export const notifyUser = `${utilsBaseURL}/notifyUser`;

export const signInCheck = `${utilsBaseURL}/signinCheck`;
export const isEmailExists = `${utilsBaseURL}/emailIDExists`;

export const teamNameCheck = `${utilsBaseURL}/teamNameAvailability/`;

export const getStartedQues = `${utilsBaseURL}/getStartedQues`;

export const editSpace = `${utilsBaseURL}/editSpace`;
export const deleteSpace = `${utilsBaseURL}/deleteSpace`;

/*User Management APIs*/

export const changeTeamMemberRole = `${userBaseURL}/user`;
export const userDeboardFromSpace = `${userBaseURL}/userDeboardFromSpace`;
export const clientOnboarding = `${userBaseURL}/clientOnboarding`;
export const userOnboarding = `${userBaseURL}/userOnboarding`;
export const newSpaceName = `${userBaseURL}/newTankTeam/`;
export const getAllUsersFromSpace = `${userBaseURL}/users/`;
export const planDetails = `${userBaseURL}/getPlanDetails/`;
export const invalidUser = `${api}/invalidUser`;
export const updatePlan = `${userBaseURL}/updatePlan`;

/*Query Management APIs*/

export const questionsList = `${queryBaseURL}/collectionQuestions/`;
export const collectionsList = `${queryBaseURL}/collectionlist`;
export const questionDetails = `${queryBaseURL}/question/`;
export const createNewQuestion = `${queryBaseURL}/createQuestion`;
export const updateQuestionByID = `${queryBaseURL}/updateQuestion/?quesId=`;
export const deleteQuestionAPI = `${queryBaseURL}/deleteQuestion/`;
export const addAnswer = `${queryBaseURL}/addAnswer/?quesId=`;
export const getAnswerByID = `${queryBaseURL}/answerDetailbyQues/`;
export const updateAnswerByID = `${queryBaseURL}/updateAnswer/`;
export const deleteAnswerAPI = `${queryBaseURL}/deleteAnswer/?quesId=`;
export const getCollectionDetailsByID = `${queryBaseURL}/collectionDetailsbyID/`;
export const createCollection = `${queryBaseURL}/createCollection`;
export const editCollection = `${queryBaseURL}/updateCollection/`;
export const deleteCollection = `${queryBaseURL}/deleteCollection/`;
export const generalSearch = `${queryBaseURL}/genSearch/`;
export const myPosts = `${queryBaseURL}/userPosts`;

/*Interest Management APIs*/

export const followQuery = `${queryBaseURL}/followQuery`;
export const unfollowQuery = `${queryBaseURL}/unfollowQuery`;
export const getFollowedQueries = `${queryBaseURL}/getFollowedQueries`;

/*Drafts Management APIs*/

export const saveAsDraftQuestion = `${draftBaseURL}/saveQuesDraft`;
export const saveAsDraftAnswer = `${draftBaseURL}/saveAnsDraft`;
export const getMyDrafts = `${draftBaseURL}/getMyDrafts`;
export const deleteQuestionDraft = `${draftBaseURL}/deleteDraft/Ques/`;
export const deleteAnswerDraft = `${draftBaseURL}/deleteDraft/Answer/`;
export const updateAnswerDraft = `${draftBaseURL}/updateAnsDraft/?draftID=`;
export const updateQuestionDraft = `${draftBaseURL}/updateQuesDraft/?draftID=`;

/*Notification Management APIs*/

export const getAllNotifications = `${notificationBaseURL}/getAllNotifications`;
export const getActiveTasks = `${notificationBaseURL}/getActiveTasks`;
export const verifyAnswer = `${notificationBaseURL}/verifyAnswer`;
export const subscribeEvents = `${notificationBaseURL}/subscribe/events`;
export const updateMentionTask = `${notificationBaseURL}/updateMentionTask`;

/*Slack Microservice Backend APIs*/

export const getSlackChannels = `${slackBaseURL}/getSlackChannels`;
export const getSlackChannelMembers = `${slackBaseURL}/getSlackChannelMembers`;
export const onboardSelectedSlackUsers = `${slackBaseURL}/onboardSelectedSlackUsers`;
export const uninstallSlackIntegration = `${slackBaseURL}/uninstallSlackIntegration`;
export const getSlackState = "slack/getState";

/*Stripe Gateway Microservice Backend APIs*/

export const createCustomer = `${stripeBaseURL}/create-customer`;
export const createSubscription = `${stripeBaseURL}/create-subscription`;
export const checkPaymentStatus = `${stripeBaseURL}/checkPaymentStatus`;
export const createCheckoutSession = `${stripeBaseURL}/create-checkout-session`;
export const stripeGetInvoices = `${stripeBaseURL}/get-invoices`;
export const getPaymentInfo = `${stripeBaseURL}/get-payment-methods`;
export const stripeCustomerDetails = `${userBaseURL}/stripeUser`;

/* Native API*/

export const getCurrentUserDetails = `${api}/getCurrentUserDetails`;
export const passwordReset = `${api}/password-reset`;

/*Analytics API*/
export const analyticsTeamActivity = `/analytics`;
