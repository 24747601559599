export const DRAWER_WIDTH = 270;

export const TYPING_SPEED = 60;
export const DELETING_SPEED = 60;

export const COLOR_PALETTE = [
  "#fae187",
  "#ffcdd2",
  "#b4f2db",
  "#d7d9e9",
  "#f5f5f5",
  "#b5dcf9",
  "#f4ac7f",
  "#ff8c8c",
  "#abb6ae",
];

export const COLLECTION_SCOPES = ["Public", "Private"];

export const BRAND_BLUE = "#3c5ffb";
export const COLOR_BLACK = "#3f434a";

export const FOLLOW = "Follow";
export const UNFOLLOW = "Unfollow";
export const EDIT = "Edit";
export const MOVE = "Move";
export const ANALYTICS = "Analytics";
export const DELETE = "Delete";

export const appSubdomain = "trytank.io";
export const blogSubdomain = "blog.trytank.io";

export const ORIGIN_TANK = "TANK";
export const ORIGIN_SLACK = "slack";

export const QUESTION_OPTIONS = [
  {
    optionId: 1,
    optionText: EDIT,
  },
  {
    optionId: 2,
    optionText: MOVE,
  },
  {
    optionId: 3,
    optionText: DELETE,
  },
  // {
  //   optionId: 4,
  //   optionText: ANALYTICS
  // }
];

export const QUESTION_OPTIONS_ADMIN = [
  {
    optionId: 1,
    optionText: MOVE,
  },
  {
    optionId: 2,
    optionText: DELETE,
  },
  // {
  //   optionId: 4,
  //   optionText: ANALYTICS
  // }
];

export const DRAFT_QUESTION_OPTIONS = [
  {
    optionId: 1,
    optionText: EDIT,
  },
  {
    optionId: 2,
    optionText: DELETE,
  },
];

export const COLLECTION_MODIFY_OPTIONS = [
  {
    optionId: 1,
    optionText: EDIT,
  },
  {
    optionId: 2,
    optionText: DELETE,
  },
];

export const BASIC_PLAN_FEATURES = [
  "50 collections, unlimited docs",
  "Slack integration",
  "Invitation management",
  "Manage Information Sharing",
  "Track topics of interest",
  "Verified content management",
];

export const PRO_PLAN_FEATURES = [
  "+ Basic plan",
  "Unlimited collections, docs",
  "Team insights",
  "AI based analytics",
  "Duplicate detection (Coming soon)",
  "Content Gap Analysis (Coming soon)",
  "Backup and Download of data",
];

export const pricingData = [
  {
    planTitle: "BASIC",
    monthlyAmount: "6",
    yearlyAmount: "4",
    features: BASIC_PLAN_FEATURES,
    planLink: "GET STARTED",
  },
  {
    planTitle: "PRO",
    monthlyAmount: "8",
    yearlyAmount: "6",
    planLink: "GET STARTED",
    features: PRO_PLAN_FEATURES,
  },
];

export const ROUTES = [
  "dashboard",
  "signin",
  "forgot-password",
  "password-reset",
  "signup",
  "setting-up",
  "logout",
  "organization",
  "signup-invite",
  "user-invite",
];

export const TANK_LOGO_BLUE = require("../assets/images/t-logo.png");
export const TANK_LOGO_WHITE = require("../assets/images/tank-white-logo.png");
export const TANK_LOADER = require("../assets/images/tank-loader.gif");
